import { useQuery } from 'react-query'

import { IUser, UserLogInProfile } from '@/types'
import { createCustomTokenAction } from '@/actions/create-custom-token'
import { getUserAction } from '@/actions/get-user'
import { getPendingRegistrationsActions } from '@/actions/get-pending-registrations'

export const loginDevelopUser = async (user: UserLogInProfile) => {
  const customToken = await createCustomTokenAction(user)
  return customToken
}

export enum QueryKey {
  getUser = 'get-user',
  getPendingRegistrations = 'get-pending-registrations'
}

export const useUser = () => {
  const result = useQuery(QueryKey.getUser, () => getUserAction())
  return {
    ...result,
    user: result.data as IUser
  }
}

export const usePendingRegistrations = (userId: number | null) => {
  const result = useQuery(
    QueryKey.getPendingRegistrations,
    () => getPendingRegistrationsActions(userId),
    { enabled: !!userId }
  )
  return {
    ...result,
    registrations: result.data ?? []
  }
}
