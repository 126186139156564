import { isEmpty, isEqual, xorWith, find, pickBy } from 'lodash'
import {
  ISportAgeGroup,
  ISportItem,
  ISportSerie,
  IRace,
  IRaceStart,
  IRaceRound,
  IRoundCompetitorExtended
} from '../types'

import dayjs from 'dayjs'
import 'dayjs/locale/fi'

dayjs.locale('fi')

export const generateId = (length = 20) => Math.random().toString(20).substr(2, length)

export enum Routes {
  Home = '/',
  Organizer = '/organizer',
  SportConfig = '/sport-config',
  RaceApproval = '/race-config',
  ShoppingBasket = '/shopping-basket',
  OwnInfo = '/own-info',
  EditRace = '/edit-race',
  Race = '/kilpailu',
  Races = '/kilpailut',
  Redirect = '/redirect',
  Admin = '/admin',
  Info = 'Info',
  Auth = '/auth'
}

export enum RaceSubRoutes {
  Info = 'info',
  Regist = 'ilmoittaudu',
  Participants = 'ilmoittautuneet',
  Starts = 'lahtolistat',
  Results = 'tulokset'
}

export const DATE_FORMAT = 'ddd D.M.YYYY'
export const DATE_FORMAT_WITHOUT_DAY = 'D.M.YY'
export const DB_DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const DATE_SHORT_FORMAT = 'D.M'
export const DATE_TIME_FORMAT = `${DATE_FORMAT}T${TIME_FORMAT}`
export const DB_DATE_TIME_FORMAT = `${DB_DATE_FORMAT}T${TIME_FORMAT}`
export const DISPLAY_TIME_FORMAT = `${DATE_FORMAT}`
export const DATE_AND_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

export const getDbDate = (date: dayjs.Dayjs) => date.format(DB_DATE_FORMAT)

export const getTimestamp = () => dayjs().format(DATE_AND_TIME_FORMAT)

export const isArrayEqual = (x: any, y: any) => isEmpty(xorWith(x, y, isEqual))

export const displayDate = (timeStr: string) => dayjs(timeStr).format(DATE_FORMAT)

export const displayDateNoDay = (timeStr: string) => dayjs(timeStr).format(DATE_FORMAT_WITHOUT_DAY)

export const displayDateShort = (timeStr: string) => dayjs(timeStr).format(DATE_SHORT_FORMAT)

export const displayDateAndTime = (timeStr: string) => dayjs(timeStr).format(DATE_AND_TIME_FORMAT)

export const getSportById = (sports: ISportItem[], sportId: string) =>
  find(sports, (sport) => sport.id.toString() === sportId) as ISportItem

export const getSportSerieById = (sport: ISportItem, serieId: string) =>
  find(sport.series, (serie: ISportSerie) => serie.id === serieId) as ISportSerie

export const getSportAgeGroupById = (sport: ISportItem, ageGroupId: string) =>
  find(sport.ageGroups, (ageGroup: ISportAgeGroup) => ageGroup.id === ageGroupId) as ISportAgeGroup

export const compareRaceDate = (a: IRace, b: IRace) => {
  return compareDateDescending(a.dates[0], b.dates[0])
}

export const compareRaceDateAscending = (a: IRace, b: IRace) => {
  return compareDate(a.dates[0], b.dates[0])
}

export const compareDate = (a: string, b: string) => {
  if (dayjs(a).isAfter(dayjs(b))) {
    return 1
  }
  if (dayjs(b).isAfter(dayjs(a))) {
    return -1
  }
  return 0
}

export const compareDateDescending = (a: string, b: string) => {
  if (dayjs(a).isBefore(dayjs(b))) {
    return 1
  }
  if (dayjs(b).isBefore(dayjs(a))) {
    return -1
  }
  return 0
}

export const compareExtendedRounds = (a: IRaceRound, b: IRaceRound) => {
  const startTimeA = a.competitors[0]?.startTime
  const startTimeB = b.competitors[0]?.startTime
  const timeA = startTimeA ? dayjs(`2018-04-13 ${startTimeA}`) : null
  const timeB = startTimeB ? dayjs(`2018-04-13 ${startTimeB}`) : null
  if (startTimeA && !startTimeB) {
    return -1
  }
  if (!startTimeA && startTimeB) {
    return 1
  }
  if (timeA && timeB) {
    if (timeA.isBefore(timeB)) {
      return -1
    } else if (timeB.isBefore(timeA)) {
      return 1
    } else {
      return 0
    }
  }

  return 0
}

export const compareSeries = (a: ISportSerie, b: ISportSerie) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
}

export const compareAgeGroups = (a: ISportAgeGroup, b: ISportAgeGroup) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
}

export const compareStarts = (sport: ISportItem) => (a: IRaceStart, b: IRaceStart) => {
  let ret = compareDate(a.date, b.date)
  if (ret !== 0) {
    return ret
  }
  if (a.distance > b.distance) {
    return 1
  }
  if (a.distance < b.distance) {
    return -1
  }

  try {
    const { name: serieNameA } = getSportSerieById(sport, a.serieId)
    const { name: serieNameB } = getSportSerieById(sport, b.serieId)
    if (serieNameA > serieNameB) {
      return 1
    }
    if (serieNameA < serieNameB) {
      return -1
    }

    const { name: ageGroupNameA } = getSportAgeGroupById(sport, a.ageGroupId)
    const { name: ageGroupNameB } = getSportAgeGroupById(sport, b.ageGroupId)
    if (ageGroupNameA > ageGroupNameB) {
      return 1
    }
    if (ageGroupNameA < ageGroupNameB) {
      return -1
    }
  } catch (e) {
    console.log('compareStarts error', e)
  }

  return 0
}

export const getShortTeamName = (teamName: string) => {
  return teamName
    .split('/')[0]
    .replace(' ry', '')
    .replace(' Ry', '')
    .replace(' rf', '')
    .replace('Smark', 'SMARK')
    .replace('Soutuseura SMARK', 'SMARK')
}

export const comparePosition = (
  a: IRoundCompetitorExtended | null,
  b: IRoundCompetitorExtended | null
) => {
  if (!a || !b) {
    return 0
  }
  const durationA = a.duration
  const durationB = b.duration

  if (!!durationA && !!durationB) {
    if (durationA > durationB) {
      return 1
    } else if (durationA < durationB) {
      return -1
    } else {
      return 0
    }
  } else if (!!durationA && !durationB) {
    return -1
  } else if (!!durationB && !durationA) {
    return 1
  } else {
    return 0
  }
}

export const getYears = () => {
  const years = []
  const currentYear = dayjs().year()
  const startYear = 2021
  for (let i = startYear; i <= currentYear; i++) {
    years.push(i.toString())
  }
  return years
}

export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export function removeUndefined(originalObject) {
  const cleanedObject = pickBy(originalObject, (v) => v !== undefined)
  return cleanedObject
}
