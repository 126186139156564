'use client'

import { Routes } from '@/util'
import { Typography } from '@mui/material'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

const LoginLink = () => {
  const pathname = usePathname()
  if (pathname !== Routes.Auth) {
    return (
      <Link href={'/auth'}>
        <Typography variant="button">Kirjaudu</Typography>
      </Link>
    )
  }
  return null
}

export default LoginLink
