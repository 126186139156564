'use client'
import React from 'react'
import {
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  Divider,
  ListItemIcon,
  Badge,
  Avatar,
  Tooltip
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

import { IUser } from '../types'
import { Routes } from '../util'
import { ExitToApp, Home, Settings, ShoppingCartRounded } from '@mui/icons-material'
import { useRouter } from 'next/navigation'
import { logoutAction } from '@/actions/logout'
import { useQueryClient } from 'react-query'

type UserMenuContentProps = {
  user: IUser
  registrationCount: number
}
const UserMenuContent = ({ user, registrationCount }: UserMenuContentProps) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const hasPendingRegistrations = registrationCount > 0

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = async () => {
    await logoutAction()
    queryClient.invalidateQueries()
    handleClose()
    router.refresh()
  }

  const handleSelection = (route: Routes) => {
    router.push(route)
    handleClose()
  }

  const names = user.name.split(' ')

  const shortName = names
    .map((name) => {
      return name[0].toUpperCase()
    })
    .join('')

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        <Box aria-controls="simple-menu" aria-haspopup="true" color="inherit" display="flex">
          {hasPendingRegistrations && (
            <Box mr={2} display="flex" alignItems="center">
              <Badge color="secondary" badgeContent={registrationCount}>
                <ShoppingCartRounded fontSize="small" />
              </Badge>
            </Box>
          )}
          <Box mr={2} display="flex" alignItems="center">
            <Tooltip title={`${user.name} (${user.sportId})`}>
              <Avatar>{shortName}</Avatar>
            </Tooltip>
          </Box>
          <Box
            sx={{ mr: 2, display: { xs: 'none', sm: 'none', md: 'block' } }}
            display="flex"
            alignItems="center"
          >
            <Typography variant="h6">{user.name}</Typography>
          </Box>
          <Box ml={0} display="flex" alignItems="center">
            <MenuIcon />
          </Box>
        </Box>
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        id="simple-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelection(Routes.Home)}>
          <ListItemIcon>
            <Home fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Pääsivu</Typography>
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <Typography variant="inherit">Kilpailija</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleSelection(Routes.OwnInfo)}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Omat tiedot</Typography>
        </MenuItem>
        <MenuItem
          disabled={!hasPendingRegistrations}
          onClick={() => handleSelection(Routes.ShoppingBasket)}
        >
          <ListItemIcon>
            <Badge color="secondary" badgeContent={registrationCount}>
              <ShoppingCartRounded fontSize="small" />
            </Badge>
          </ListItemIcon>
          <Typography variant="inherit">Ostoskori</Typography>
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <Typography variant="inherit">Kilpailun järjestäjä</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleSelection(Routes.Organizer)}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Hallinnoi kilpailuja</Typography>
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <Typography variant="inherit">Liittokäyttäjä</Typography>
        </MenuItem>
        <MenuItem
          disabled={!user.isFederationUser}
          onClick={() => handleSelection(Routes.SportConfig)}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Hallinnoi lajeja</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Kirjaudu ulos</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenuContent
