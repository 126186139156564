'use client'
import React from 'react'

import UserMenuContent from '@/components/UserMenuContent'
import LoginLink from '@/components/LoginLink'
import { usePendingRegistrations, useUser } from '@/hooks/user'

const UserMenu = () => {
  const { user } = useUser()
  const { registrations } = usePendingRegistrations(user?.userId ?? null)
  if (user) {
    return <UserMenuContent user={user} registrationCount={registrations.length} />
  } else if (user === null) {
    return <LoginLink />
  } else {
    return null
  }
}

export default UserMenu
